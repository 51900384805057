<template>
  <div class="registerInfo">
    <div class="publish-content">
      <div class="publish-content-info">
        <div class="content-info-l">真实姓名：</div>
        <div class="content-info-r">
          <v-input
            placeholder="请填写真实姓名"
            inputAlign="right"
            v-model="form.realName"
          ></v-input>
        </div>
      </div>
      <div class="line"></div>
    </div>
    <div class="publish-content">
      <div class="publish-content-info">
        <div class="content-info-l">手机号码：</div>
        <div class="content-info-r">
          <v-input
            placeholder="请填写手机号码"
            inputAlign="right"
            type="number"
            v-model="form.mobile"
          ></v-input>
        </div>
      </div>
      <div class="line"></div>
    </div>
    <div class="publish-content">
      <div class="publish-content-info">
        <div class="content-info-l">是否为家友：</div>
        <div class="content-info-r family-friend">
          <v-radio-group
            :radioObj="isFamilyFriend"
            :value.sync="form.isRelated"
          ></v-radio-group>
        </div>
      </div>
      <div class="line"></div>
    </div>
    <div class="publish-content">
      <div class="publish-content-info">
        <div class="content-info-l">住址：</div>
        <div class="content-info-r">
          <v-input
            placeholder="请填写住址"
            inputAlign="right"
            v-model="form.address"
          ></v-input>
        </div>
      </div>
      <div class="line"></div>
    </div>
    <div class="publish-content">
      <div class="publish-content-info">
        <div class="content-info-l">需求类型：</div>
        <div class="content-info-r type">
          <div class="text">
            {{ form.demandType ? form.demandType : "" }}
          </div>
        </div>
      </div>
      <div class="line"></div>
    </div>
    <div class="publish-content">
      <div class="publish-content-info">
        <div class="content-info-l">需求描述 ：</div>
      </div>
      <div class="serve-introduce">
        <v-input
          placeholder="请输入"
          :maxlength="500"
          v-model="form.demandDesc"
          type="textarea"
        />
      </div>
      <div class="line"></div>
    </div>
    <div class="publish-content">
      <div class="publish-content-info">
        <div class="content-info-l">上传图片 ：</div>
      </div>
      <div class="picture">
        <v-upload
          class="oItem-b_upload"
          :imgUrl.sync="form.imageUrl"
          :activityPicture.sync="form.imageUrl"
          ref="load"
          :maxCount="9"
        ></v-upload>
      </div>
      <div class="line"></div>
    </div>
    <div class="promise">
      <v-checkBox checkedColor="#8A95A6" v-model="isConsent"></v-checkBox>
      <span @click="confirm">承诺书</span>
    </div>
    <div class="submit-btn" @click="submit">确定</div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { saveOrUpdateCoffeeDemand } from "./api.js";
import { mapState } from "vuex";
export default {
  name: "registerInfo",
  components: {},
  props: {},
  data() {
    return {
      isChecked: true,
      isFamilyFriend: [
        { name: 0, value: "否", checkedColor: "#8A95A6" },
        { name: 1, value: "是", checkedColor: "#8A95A6" },
      ],
      form: {
        realName: "", // 真实姓名
        mobile: "", //手机号码
        isRelated: 0, //是否为家友
        address: "", //住址
        demandType: "", //需求类型
        imageUrl: "", //图片
        demandDesc: "", //需求描述
        isSigned: 0, //是否签署承诺书
      },
      isConsent: false,
      flag: true,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  watch: {
    isConsent: {
      handler(v) {
        v ? (this.form.isSigned = 1) : (this.form.isSigned = 0);
      },
      immediate: true,
      deep: true,
    },
  },
  filters: {},
  created() {
    const { type, isConsent } = this.$route.query;
    if (type != undefined) {
      this.demandType = type;
    }
    if (localStorage.getItem("registerInfo")) {
      const params = JSON.parse(localStorage.getItem("registerInfo"));
      Object.keys(this.form).forEach((keys) => {
        this.form[keys] = params[keys];
      });
    }
    if (isConsent) {
      this.isConsent = Boolean(isConsent);
      this.isConsent ? (this.form.isSigned = 1) : (this.form.isSigned = 0);
    }
  },
  mounted() {},
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name === "letterOfCommitment") {
        localStorage.removeItem("registerInfo")
      }
    });
  },
  methods: {
    submit() {
      if (!this.form.realName) {
        this.$toast({ message: "请输入真实姓名", duration: 2000 });
        return;
      }
      if (!this.form.mobile) {
        this.$toast({ message: "请输入电话号码", duration: 2000 });
        return;
      }
      if (!this.form.address) {
        this.$toast({ message: "请输入住址", duration: 2000 });
        return;
      }
      if (!this.form.demandDesc) {
        this.$toast({ message: "请输入描述", duration: 2000 });
        return;
      }
      if (!this.form.imageUrl) {
        this.$toast({ message: "请上传图片", duration: 2000 });
        return;
      }
      if (!this.isConsent) {
        this.$toast({ message: "请勾选承诺书！", duration: 2000 });
        return;
      }

      if (this.flag) {
        this.flag = false;
        const params = {
          ...this.form,
          insertUser: this.userId,
        };
        this.$axios
          .post(saveOrUpdateCoffeeDemand, params)
          .then((res) => {
            console.log("res----->", res);
            if (res.code == 200) {
              this.flag = true;
              this.$toast({ message: res.msg, duration: 2000 });
              this.$router.replace({
                name: "aCupOfCoffee",
              });
            }
          })
          .catch(() => {
            this.flag = true;
          });
      }
    },
    confirm() {
      localStorage.setItem("registerInfo", JSON.stringify(this.form));
      this.$router.push({
        name: "letterOfCommitment",
      });
    },
  },
};
</script>

<style scoped lang="less">
.registerInfo {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 22px 44px 0 42px;
  padding-bottom: calc(150px + constant(safe-area-inset-bottom));
  padding-bottom: calc(150px + env(safe-area-inset-bottom));
  .publish-content {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 40px;

    .publish-content-info {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding-left: 10px;
      margin-bottom: 28px;
      .content-info-l {
        white-space: nowrap;
        width: 226px;
        font-size: 32px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 44px;
      }
      .content-info-r {
        &.family-friend {
          display: flex;
          justify-content: flex-end;
          ::v-deep .van-radio__icon {
            font-size: 28px;
          }
          ::v-deep .van-radio__label {
            font-size: 28px;
          }
        }
        &.type {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          .text {
            padding: 10px 16px;
            padding-right: 0;
            font-size: 28px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
            line-height: 40px;
          }
        }

        flex: 1;
        ::v-deep .van-cell {
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 40px;
          padding-right: 0;
        }
        ::v-deep .van-field__control::-webkit-input-placeholder {
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 40px;
        }
      }
    }
    .serve-introduce {
      width: 100%;
      overflow: hidden;
      border-radius: 16px;
      min-height: 270px;
      margin-bottom: 28px;
      // min-height: 270px;
      > div {
        width: 100%;
        min-height: 270px;
        ::v-deep .van-cell {
          background: #f8f8f8;
          width: 100%;
          min-height: 270px;
          .van-field__body {
            width: 100%;
            min-height: 270px;
            textarea {
              min-height: 270px !important;
            }
          }
        }
      }
    }
    .picture {
      margin-bottom: 28px;
    }
    .line {
      width: 100%;
      height: 2px;
      background-color: rgba(0, 0, 0, 0.06);
    }
  }
  .promise {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-bottom: 48px;
    span {
      cursor: pointer;
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #8a95a6;
      line-height: 40px;
    }
  }
  .submit-btn {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 70px;
    width: 550px;
    height: 70px;
    background: #8a95a6;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 42px;
  }
}
</style>
